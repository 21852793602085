<template>
    <div>
      <PageLoader :storage="appLoading" />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #fff">{{ msg }}</span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #fff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-layout wrap justify-start pa-5>
            <v-flex xs12 lg8 md8 sm12>
              <span class="heading">{{ districtName }}</span>
            </v-flex>
            <v-flex xs12 lg4 md4 sm12 class="text-end" v-if="items && items.length > 0">
              <v-btn
                color="#fdac53"
                class="downlink"
                target="_blank"
                @click="getExcel()"
              >
                <span
                  style="
                    font-size: 12px;
                    font-family: poppinssemibold;
                    color: black;
                  "
                >
                  Excel<v-icon color="black">mdi-microsoft-excel</v-icon></span
                >
              </v-btn>
            </v-flex>
            <v-flex xs12>
              <span class="subheading"
                >Total Records Found: {{ totalcount }}</span
              >
            </v-flex>
            <v-flex xs12 v-if="items && items.length > 0">
            <v-layout wrap justify-start pt-2>
              <v-flex xs12>
                <v-card>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center tableheading">
                            Reported Date
                          </th>
                          <th class="text-center tableheading">Animal</th>
                          <th class="text-center tableheading">Scenario</th>
                          <th class="text-center tableheading">Location</th>
                          <th class="text-center tableheading">Threat Level</th>
                          <th class="text-center tableheading">Division</th>
                          <th class="text-center tableheading">Range</th>
                          <th class="text-center tableheading">Status</th>
                          <!-- <th class="text-center tableheading">Details</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                          <td class="tablesubheading text-center">
                            {{ formatDate(item.reportDate) }}
                          </td>
                          <td class="tablesubheading text-center">
                            <span v-if="item.animalId && item.animalId.name">{{
                              item.animalId.name
                            }}</span>
                            <span v-else>Unknown</span>
                          </td>
                          <td class="tablesubheading text-center">
                            <span
                              v-if="item.scenarioId && item.scenarioId.name"
                              >{{ item.scenarioId.name }}</span
                            >
                            <span v-else>Unknown</span>
                          </td>
                          <td class="tablesubheading text-center">
                            <span v-if="item.locationname">{{
                              item.locationname
                            }}</span>
                            <span v-else>Unknown</span>
                          </td>
                          <td class="tablesubheading text-center">
                            <span v-if="item.threatlevel">{{
                              item.threatlevel
                            }}</span>
                            <span v-else>Unknown</span>
                          </td>
                          <td class="tablesubheading text-center">
                            <span v-if="item.divisions.length > 0">
                              <span
                                v-for="(item1, i) in item.divisions"
                                :key="i"
                              >
                                <span v-if="item1.divisionName"
                                  >{{ item1.divisionName }}
                                  <span v-if="i < item.divisions.length - 1"
                                    >,
                                  </span></span
                                >
                              </span>
                            </span>
                            <span v-else> - </span>
                          </td>
                          <td class="tablesubheading text-center">
                            <span v-if="item.ranges.length > 0">
                              <span v-for="(item2, i) in item.ranges" :key="i">
                                <span v-if="item2.rangeName"
                                  >{{ item2.rangeName }}
                                  <span v-if="i < item.ranges.length - 1"
                                    >,
                                  </span></span
                                >
                              </span>
                            </span>
                            <span v-else> Unknown </span>
                          </td>
                          <td class="tablesubheading text-center">
                            <span v-if="item.status">{{ item.status }}</span>
                            <span v-else>Unknown</span>
                          </td>
                          <!-- <td class="tablesubheading text-center">
                            <span v-if="casestatus !== 'Resolved'">
                              <v-btn
                                color="success"
                                depressed
                                small
                                @click="openviewdetails(item)"
                                ><span>View Details</span></v-btn
                              >
                            </span>
                            <span v-if="casestatus === 'Resolved'">
                              <v-btn
                                color="success"
                                depressed
                                small
                                @click="openresolvedviewdetails(item)"
                                ><span>View Details</span></v-btn
                              >
                            </span>
                          </td> -->
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 pb-4 v-if="items && items.length > 0">
          <v-layout wrap justify-center>
            <v-flex xs10>
              <v-pagination
                v-model="currentPage"
                :length="Pagelength"
                circle
                color="#FF6907"
                small
              ></v-pagination>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 v-else pl-lg-3>
          <v-layout wrap justify-center pl-5>
            <v-flex xs12 class="text-center">
              <span class="heading">No Data Found!Apply Filters.</span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-dialog v-model="deleteDialog" max-width="500">
        <v-card>
          <v-card-title class="heading">Delete Rescuer</v-card-title>
          <v-card-text>
            Are you sure you want to delete this rescuer?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="deleteDialog = false"
              class="itemValue"
            >
              Cancel
            </v-btn>
            <v-btn
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="deleteUser()"
              class="itemValue"
            >
              <span style="color: #fff">Confirm</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
              <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        count: 20,
        keyword: "",
        currentPage: 1,
        Pagelength: 0,
        userlist: [],
        districts: [
          "Palakkad",
          "Alappuzha",
          "Malappuram",
          "Kannur",
          "Wayanad",
          "Kozhikode",
          "Thrissur",
          "Thiruvananthapuram",
          "Kottayam",
          "Idukki",
          "Ernakulam",
          "Kollam",
          "Pathanamthitta",
          "Kasaragod",
        ],
        deleteDialog: false,
        items: [],
        divisionname: "",
        divisionList: [],
        rangeList: [],
        rangename: "",
        rrtname: "",
        rrtList: [],
        animalTypes: [],
        animaltype: "",
        scenariodata: [],
        scenarioname: "",
        casestatus: "Active",
        caseStatuslist: ["Active", "Assigned", "Resolved", "Released"],
        menu: false,
        menu1: false,
        todate: null,
        date: null,
        blockdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        threatLevels: ["High", "Medium", "Low"],
        threatlevel: "",
  
        alldata: true,
        districtName: this.$route.query.name,
        timeperiod: false,
        casestatuscheck: false,
        divisioncheck: false,
        rangecheck: false,
        rrtcheck: false,
        yearwise: false,
        animalcheck: false,
        scenariocheck: false,
        threatlevelcheck: false,
        totalcount: 0,
        yearwisedata: ["2024", "2025"],
        yearwiseselect: "",
      };
    },
    beforeMount() {
      this.getData();
      // this.getDivisionData();
      // this.getRangeData();
      // this.getRRTData();
      // this.getanimalData();
      // this.getscenarioData();
    },
    watch: {
      currentPage() {
        this.getData();
      },
      divisionname() {
        this.getRangeData();
      },
      rangename() {
        this.getRRTData();
      },
      casestatus() {
        this.getData();
      },
      count() {
        this.getData();
      },
      keyword() {
        this.getData();
      },
      date() {
        this.todate == null;
      },
    },
    computed: {
      appUser() {
        return this.$store.state.userRole;
      },
    },
    methods: {
      openviewdetails(item) {
        this.$router.push({
          path: "/Admin/cases/caseDetails/?id=",
          query: {
            id: item._id,
          },
        });
      },
      checkUpdate() {
        if (this.alldata == true) {
          this.timeperiod = false;
          this.casestatuscheck = false;
          this.divisioncheck = false;
          this.rangecheck = false;
          this.rrtcheck = false;
          this.animalcheck = false;
          this.scenariocheck = false;
          this.threatlevelcheck = false;
          this.date = null;
          this.todate = null;
          this.divisionname = null;
          this.rangename = null;
          (this.rrtname = null), (this.casestatus = "Active");
          this.animaltype = null;
          this.scenarioname = null;
          this.threatlevel = null;
          this.yearwiseselect = null;
          this.yearwise = false;
          this.getData();
        }
      },
      openresolvedviewdetails(item) {
        this.$router.push({
          path: "/Admin/cases/caseDetails",
          query: {
            id: item.reportId,
            status: "Resolved", // Add the status parameter here
          },
        });
      },
      getData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/admin/dashboard/animal/captured/list",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            // division: this.divisionname,
            // range: this.rangename,
            // rrt: this.rrtname,
            // animalId: this.animaltype,
            // scenarioId: this.scenarioname,
            // status: this.casestatus,
            // fromDate: this.date,
            // toDate: this.todate,
            page: this.currentPage,
            limit: this.count,
            filter:this.$route.query.filter,
            // threatlevel: this.threatlevel,
            // year: this.yearwiseselect,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.items = response.data.data;
                this.totalcount = response.data.data.length;
                // var temp = [];
                // for (var i = 0; i < this.items.length; i++) {
                //   if (this.items[i].reportId != null)
                //     var it = this.items[i].reportId;
                //   it._id = this.items[i]._id;
                //   it.reportedAt = this.items[i].reportedAt;
                //   if (this.items[i].reportId != null) {
                //     if (this.items[i].reportId.snake)
                //       it.snake = this.items[i].reportId.snake.name;
                //   }
                //   temp.push(it);
                // }
                // this.items = temp;
                this.Pagelength = response.data.totalPages;
                // this.msg = response.data.msg;
                // this.showSnackBar = true;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            (this.ServerError = true), console.log(err);
          });
      },
      getDivisionData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/platform/division/getlistdropdown",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.divisionList = response.data.data;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      getRangeData() {
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/platform/range/getlistdropdown",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            division: this.divisionname,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.rangeList = response.data.data;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      getRRTData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/rescuer/rrt/getlist",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            division: this.divisionname,
            range: this.rangename,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.rrtList = response.data.data;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      getanimalData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/animal/list",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.animalTypes = response.data.data;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      getExcel() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/admin/dashboard/animal/captured/excel",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          responseType: "blob",
          params: {
            // division: this.divisionname,
            // range: this.rangename,
            // rrt: this.rrtname,
            // animalId: this.animaltype,
            // scenarioId: this.scenarioname,
            // status: this.casestatus,
            // fromDate: this.date,
            // toDate: this.todate,
            page: this.currentPage,
            limit: this.count,
            filter:this.$route.query.filter,
            // threatlevel: this.threatlevel,
            // year: this.yearwiseselect,
          },
        })
          .then((response) => {
            this.appLoading = false;
            const url = URL.createObjectURL(
              new Blob([response.data], {
                type: "application/vnd.ms-excel",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "animalscapturedreport.xlsx");
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      getscenarioData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/scenario/list",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.scenariodata = response.data.data;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      formatDate(date) {
        if (!date) return "-";
        const [year, month, day] = date.slice(0, 10).split("-");
        return `${day}-${month}-${year}`;
      },
    },
  };
  </script>
              <style>
  .heading {
    font-family: poppinsregular;
    font-size: 20px;
    font-weight: 900;
  }
  .subheading {
    font-family: poppinsregular;
    font-size: 15px;
    font-weight: 500;
  }
  .tableheading {
    font-family: poppinsregular;
    font-size: 15px !important;
    color: black !important;
    text-transform: uppercase !important;
  }
  .tablesubheading {
    font-family: poppinsregular;
    font-size: 13px !important;
  }
  </style>